const Classes = {
    'table-container': {
        'justify-center': true,
        'w-full': true,
        flex: true,
        'border-gray-200': true,
        'inline-block': true,
        'min-w-full': true,
        'rounded-lg': true,
        'overflow-hidden': true
    },
    table: {
        'text-left': true,
        'w-full': true,
        'mt-4': true
    },
    't-body': {
        'bg-white': true
    },
    't-head-tr': {
        'border-b': true,
        'border-gray': true
    },
    't-body-tr': {
        'stripped-table': true,
        'bg-grey-darkest': true
    },
    td: {
        'py-4': true,
        'px-6': true,
        'border-b': true,
        'border-grey-light': true,
        'text-gray-light': true
    },
    th: {
        'text-gray-dark': true,
        'border-gray': true,
        'border-b-2': true,
        'border-t-2': true,
        'border-gray-200': true,
        'py-3': true,
        'px-4': true,
        'bg-gray-100': true,
        'text-left': true,
        'text-xs': true,
        'font-semibold': true,
        'text-gray-600': true,
        uppercase: true,
        'tracking-wider': true
    }
};

export { Classes };
