<template>
    <div class="bg-white " :class="tableClasses">
        <div class="pt-2 pb-2">
            <div class="border-2 mb-1 rounded-2xl w-6 flex border-gray-300"></div>
            <h2 class="text-l flex">
                <div class="text-gray-600 font-semibold">{{ title }}</div>
            </h2>
        </div>

        <div class="pb-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4" v-if="options.cards">
            <div class="p-2 bg-white border-2 rounded-2xl">
                <div class="p-2">
                    <div class="border-2 mb-1 rounded-2xl w-6 flex border-gray-300"></div>
                    <div>
                        <div class="text-gray-600 flex justify-between">
                            <div class="w-40 pr-6 font-semibold text-xs">Total de vendas no período</div>
                        </div>
                        <div class="flex justify-between pt-6">
                            <div class="text-sm font-bold text-gray-600 h-5 w-16">
                                <div v-if="loading" class="load-wraper w-16">
                                    <div class="activity"></div>
                                </div>
                                <div v-else>
                                    {{ total }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <data-table
            :classes="classes"
            framework="tailwind"
            :columns="columns"
            :data="data"
            :translate="translate"
            :debounce-delay="3"
            @on-table-props-changed="reloadTable"
            @row-clicked="itemSelected"
            :pagination="paginationParams"
        >
            <div slot="filters" v-if="!options.topFilters" slot-scope="{}"></div>
            <div slot="pagination" v-if="!options.pagination" slot-scope="{}"></div>
        </data-table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ApiService } from '@/services/api.service';
import { Classes } from './classes';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    name: 'DefaultTable',
    components: {
      // VueJsonPretty
    },
    props: {
        title: {
            type: String,
            default: 'Título'
        },
        endpoint: {
            type: String,
            default: ''
        },
        options: {
            type: Object,
            default: () => ({
                type: 'default',
                cards: true,
                topFilters: true,
                pagination: true,
                placeholderSearch: 'Ex.: Clivale'
            })
        },
        columns: {
            type: Array,
            default: () => []
        },
        customFilters: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            data: {},
            paginationParams: { 'hide-when-empty': true },
            translate: { nextButton: 'Next', previousButton: 'Previous', placeholderSearch: this.options.placeholderSearch },
            total: 0,
            loading: false,
            tableProps: {
                search: '',
                length: 10,
                column: 'id',
                dir: 'desc'
            },

            tablePropsDefault: {
                search: '',
                length: 10,
                column: 'id',
                dir: 'desc'
            },
            classes: Classes,
            timerDebounce: setTimeout(() => {}, 50)
        };
    },
    computed: {
        tableClasses() {
            const is_default = this.options.type === 'default';
            return { 'p-4': is_default, 'border-2': is_default, ' rounded-2xl': is_default };
        },
        ...mapGetters('filters', ['period'])
    },
    watch: {
        period() {
            this.tableProps.page = 1;
            this.tableProps.period = this.period;
            this.getData(this.tableProps);
        },

        customFilters: {
            immediate: true,
            deep: true,
            handler(filters) {
                filters.forEach(element => {
                    this.tableProps[element.name] = element.value;
                });
                this.getData(this.tableProps);
            }
        }
    },
    created() {
        this.tableProps.period = this.period;
        this.getData();
    },
    methods: {
        async getData() {
            if (this.endpoint === '') {
                return;
            }
            this.loading = true;

            clearTimeout(this.timerDebounce)
            this.timerDebounce = setTimeout(async () => {
                await ApiService.customRequest({ url: this.endpoint, params: this.tableProps })
                    .then(response => {
                        this.data = response.data;
                        this.total = response.data?.meta?.total;
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }, 500);
        },
        reloadTable(tableProps) {
            this.tableProps = tableProps;
            this.tableProps.period = this.period;
            this.getData();
        },
        itemSelected(item) {
            this.$emit('item-selected', item);
        }
    }
};
</script>

<style>
.laravel-vue-datatable-tbody-tr {
    cursor: pointer;
}
.laravel-vue-datatable-tbody-tr:hover {
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
</style>
